import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Label, Input } from 'reactstrap'

export default class DropDown extends Component {
  render() {
    const { name, handleChange, label, options } = this.props
    return (
      <FormGroup>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <Label for={label}>{label}</Label>
            <Input name={name} type="select" onChange={handleChange}>
              {options.map(option => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
            </Input>
          </div>
        </div>
      </FormGroup>
    )
  }
}
DropDown.defaultProps = {
  label: '',
  options: [],
}

DropDown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
}
