import React, { useCallback, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'

import CustomAlert from '../Alerts'
import DropDown from '../Form/DropDown'
import InputBox from '../Form/InputBox'
import InvisibleRepatcha from '../InvisibleRepatcha'

import { postData } from '../../reducers/app'
import { contactFormValidations } from '../../validations'

import useForm from '../../hooks/useForm'

import { UserContext } from '../UserContext'
import { userData } from '../../selectors/user'

import { CONTACTUS } from '../../data/apis'

const options = [
  { id: 1, name: 'General' },
  { id: 2, name: 'Filehost Issue' },
  { id: 3, name: 'Speed Issue', placeholder: '' },
  { id: 4, name: 'Download Issue', placeholder: '' },
  { id: 8, name: 'PayTM/PhonePe/GooglePay/UPI', placeholder: '' },
  { id: 5, name: 'Payment Issue', placeholder: '' },
  { id: 6, name: 'Account Issue', placeholder: '' },
  { id: 7, name: 'Premium Key Issue', placeholder: '' },
  { id: 8, name: 'Suggestion', placeholder: '' },
  { id: 9, name: 'Other', placeholder: '' },
]

const init = {
  username: '',
  email: '',
  issueType: 'General',
  message: '',
  captcha: true,
}

let contactFormData

const ContactForm = () => {
  const dispatch = useDispatch()
  const { isLoggedIn } = useContext(UserContext)
  const {
    loading,
    loaded,
    data: { msg },
    error,
  } = useSelector(({ app }) => app.contact)
  const { username, email } = useSelector(userData)

  const {
    values,
    errors,
    setValues,
    handleOnChange,
    handleSubmit,
    isSubmitting,
  } = useForm(init, contactFormValidations, val => {
    contactFormData = {
      ...val,
      subject: `${val.issueType} - ${val.username}`,
    }
    return contactFormData
  })

  useEffect(() => {
    if (isLoggedIn) {
      setValues({
        ...init,
        username,
        email,
      })
    }
  }, [isLoggedIn])

  const submitForm = useCallback(
    token => {
      dispatch(
        postData(CONTACTUS, {
          ...contactFormData,
          token,
        })
      ).then(() =>
        setValues({
          ...init,
          username,
          email,
        })
      )
      const { grecaptcha } = window
      grecaptcha.reset()
    },
    [isSubmitting]
  )

  return (
    <div className="col-sm-9">
      <div className="cboxEraForm">
        <form onSubmit={handleSubmit}>
          <InputBox
            name="username"
            label="Username"
            type="text"
            placeholder="Your Username"
            value={values.username}
            onChangeInput={handleOnChange}
            error={errors.username}
          />
          <InputBox
            name="email"
            label="Email"
            type="email"
            placeholder="Your email"
            value={values.email}
            onChangeInput={handleOnChange}
            error={errors.email}
          />
          <DropDown
            name="issueType"
            label="Select Issue"
            options={options}
            handleChange={handleOnChange}
          />
          <InputBox
            name="message"
            label="Message"
            type="textarea"
            placeholder="Please provide us as much details as possible about your issue."
            value={values.message}
            onChangeInput={handleOnChange}
            error={errors.message}
            rows="5"
          />
          <div className="clear-fix mb-3" />
          <InvisibleRepatcha onResolved={submitForm} />
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <Button type="submit" color="success" disabled={loading}>
              {loading ? 'Please wait...' : 'Submit'}
            </Button>
          </div>
        </form>
        {loaded && !loading && (
          <CustomAlert type={`${loaded ? 'success' : 'danger'}`}>
            <span>{error || msg}</span>
          </CustomAlert>
        )}
      </div>
    </div>
  )
}

export default ContactForm
