import React from 'react'

import Wrapper from '../components/Wrapper'
import ContactForm from '../components/Contact'
import SEO from '../components/SEO'

const Contact = () => (
  <>
    <SEO page="contact" />
    <Wrapper title="Contact us">
      <ContactForm />
    </Wrapper>
  </>
)

export default Contact
